import pino from 'pino';

import { configs } from '@/config/shared';

const logger = pino({
  enabled: configs.supplierCode !== 'prod-squadnet',
  browser: {
    asObject: true,
  },
});

export default logger;
