import { FieldPolicy } from '@apollo/client';
import { remove, uniqBy } from 'ramda';

import logger from './logger';

interface Pagination {
  list?: Record<string, any>[];
  meta?: Record<string, any>;
  __typename?: string;
}
export const mergeList =
  (debug?: boolean) =>
  (
    existing = { list: [], meta: {}, __typename: '' },
    incoming: Pagination,
    { variables }: Record<string, any>,
  ): Pagination => {
    if (debug) {
      console.log('****************');
      console.log(incoming);
      console.log(existing);
    }
    if (!incoming) {
      if (!existing.__typename) {
        return {};
      }
      return existing;
    }
    const { list = [], meta = {} } = existing;
    const { list: nextList = [], meta: nextMeta = [] } = incoming || {};
    let newList: Record<string, any>[] = nextList;
    let newMeta: Record<string, any> = nextMeta;
    if (debug) {
      logger.debug(variables);
      logger.debug(nextList);
    }
    // fetchMore: append incoming to existing
    if (variables?.filter?.cacheType === 'fetchMore') {
      newList = uniqBy(obj => obj.__ref, [...list, ...nextList]);
    }
    // pushNew: prepend records to list
    if (variables?.filter?.cacheType === 'pushNew') {
      newList = uniqBy(obj => obj.__ref, [...nextList, ...list]);
      newMeta = meta;
      if (debug) {
        logger.debug(newList);
      }
    }
    // removeExisting: remove records from list
    if (variables?.filter?.cacheType === 'removeExisting') {
      newList = [...list];
      nextList.forEach(item => {
        const idx = newList.findIndex(i => item.__ref === i.__ref);
        if (idx !== -1) {
          newList = remove(idx, 1, newList);
        }
      });
      newMeta = meta;
    }

    if (debug) {
      console.log({
        __typename: existing.__typename || incoming.__typename,
        list: [...newList],
        meta: { ...newMeta },
      });
    }
    return {
      __typename: existing.__typename || incoming.__typename,
      list: [...newList],
      meta: { ...newMeta },
    };
  };

export const timestampStylePagination = (
  keyArgs?: boolean | Array<string | Array<string>>,
  debug?: boolean,
): FieldPolicy<any, any, any> => {
  return {
    keyArgs: keyArgs || false,
    merge: mergeList(debug),
  } as FieldPolicy<any, any, any>;
};

export const offsetStylePagination = (
  keyArgs?: boolean | Array<string | Array<any>>,
  debug?: boolean,
): FieldPolicy<any, any, any> => {
  return {
    keyArgs: keyArgs || false,
    merge: mergeList(debug),
  } as FieldPolicy<any, any, any>;
};

export const pageStylePagination = (
  keyArgs?: boolean | Array<string | Array<string>>,
  debug?: boolean,
): FieldPolicy<any, any, any> => {
  return {
    keyArgs: keyArgs || false,
    merge: mergeList(debug),
  } as FieldPolicy<any, any, any>;
};
