import { ApolloClient, ApolloLink, InMemoryCache, NormalizedCacheObject } from '@apollo/client/core';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { Auth } from '@aws-amplify/auth';
import { AUTH_TYPE, createAuthLink } from 'aws-appsync-auth-link';

import { createSubscriptionHandshakeLink } from '@squadnet/appsync-subscription-link';

import { configs } from '@/config/shared';

import { createCache } from './apolloCache';

const errOperations = ['UpdateAuthUpdateMutation', 'CreateAuthUpdateMutation', 'OrderStatusUpdated'];

const isServer = typeof window === 'undefined';

type ReturnType = () => {
  client: ApolloClient<NormalizedCacheObject>;
  reset: () => Promise<any>;
};

const defaultHeaders =
  typeof window === 'undefined'
    ? {
        'sq-app-version': '1.0.0',
        'sq-platform': 'node',
      }
    : {
        'sq-app-version': '1.0.0',
        'sq-platform': navigator.platform,
        'sq-window-width': window.innerWidth,
        'sq-window-height': window.innerHeight,
      };

export const configure = (AuthClient = Auth): ReturnType => {
  const url = configs.graphqlEndpoint || '';
  const region = configs.awsRegion || '';
  const isLocal = configs.supplierCode === 'local-squadnet';
  const auth = {
    type: AUTH_TYPE.AWS_IAM as 'AWS_IAM',
    credentials: () => AuthClient.currentCredentials(),
  };

  let links: any = [createAuthLink({ url, region, auth })];
  if (!isServer) {
    links = [
      ...links,
      new RetryLink({
        delay: {
          initial: 300,
          max: Infinity,
          jitter: true,
        },
        attempts: {
          max: 5,
          retryIf: (error, _operation) => {
            if (error instanceof Error) {
              return true;
            }
            return false;
          },
        },
      }),
    ];
  }
  links = [
    new ApolloLink((operation, forward) => {
      let sqHeaders = {};
      if ((operation.query as any).sqHeaders) {
        sqHeaders = (operation.query as any).sqHeaders || {};
        delete (operation.query as any).sqHeaders;
      }
      operation.setContext(({ headers = {} }) => {
        return {
          headers: {
            ...headers,
            ...defaultHeaders,
            ...sqHeaders,
          },
        };
      });
      return forward(operation);
    }),
    ...links,
    new ApolloLink((operation, forward) => {
      return forward(operation).map(data => {
        if (data && data.errors && data.errors.length > 0) {
          const message = data?.errors?.[0].message || '';
          if (message.includes('Task timed out')) {
            throw new Error('GraphQL Operational Error');
          } else {
            return data;
          }
        }
        return data;
      });
    }),
    onError(({ response, operation, forward }) => {
      // TODO: Better error handler
      if (response) {
        if (errOperations.findIndex(x => operation?.operationName === x) === -1) {
          const message = response?.errors?.[0].message || '';
          if (message.includes('Task timed out')) {
            (response as any).errors = null;
            return forward(operation);
          } else {
            (response as any).errors = null;
          }
        }
      }
      return forward(operation);
    }),
    createSubscriptionHandshakeLink({ url, region, auth }),
  ];

  const link = ApolloLink.from(links);

  let cache: InMemoryCache = createCache();
  let client: ApolloClient<any>;

  const createClient = () => {
    if (client) {
      return {
        client,
        reset: () => cache?.reset(),
      };
    }

    if (isServer) {
      cache = createCache();
    }

    const clientInstance = new ApolloClient({
      connectToDevTools: isLocal ? true : false,
      link,
      cache: isServer ? new InMemoryCache({ resultCaching: false }) : cache,
      ssrMode: isServer,
      ssrForceFetchDelay: isServer ? 0 : 200,
      queryDeduplication: true,
    });

    if (!isServer) {
      client = clientInstance;
    }

    return {
      client: clientInstance,
      reset: () => cache.reset(),
    };
  };

  return createClient;
};

const createClient = configure();

export default createClient;
